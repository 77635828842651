.file {
    align-items: stretch;
    display: flex;
    justify-content: flex-start;
    position: relative;

    &-label {
        align-items: stretch;
        display: flex;
        cursor: pointer;
        justify-content: flex-start;
        overflow: hidden;
        position: relative;
    }
    
    &-cta {
        border: 1px solid #dbdbdb;
        font-size: 1em;
        padding-left: 1em;
        padding-right: 1em;
        white-space: nowrap;
    }
    
    &-name,
    &-cta {
        font-size: 1em;
        padding-left: 1em;
        padding-right: 1em;
        white-space: nowrap;
        display: flex;
        align-items: center;
    }
    
    &-name {
        border-color: #dbdbdb;
        border-style: solid;
        border-width: 1px 1px 1px 0;
        max-width: 16em;
        overflow: hidden;
        text-align: inherit;
        text-overflow: ellipsis;
        padding-top: .6em;
        padding-bottom: .6em;
        display: block;
    }
    
    &-cta {
        background-color: #f5f5f5;
        color: #4a4a4a;
        display: flex;
    }
    
    &-icon {
        align-items: center;
        display: flex;
        height: 1em;
        justify-content: center;
        margin-right: 0.5em;
        width: 1em;
    }
    
    &-input {
        height: 100%;
        left: 0;
        opacity: 0;
        outline: 0;
        position: absolute;
        top: 0;
        width: 100%;
    }
}
