html {
  font-size: 15px;
  font-family: "Poppins", "Inter", sans-serif;
  color: #580055;
}

h3,
h4,
h5,
h6 {
  font-weight: 600;
}

h1,
h2 {
  font-weight: bold;
  line-height: 1.1;
  margin-top: 0;
  color: #000;
}

h1 {
  font-size: 40px;
}

h2 {
  font-size: 3rem;
}

/* .bloc-iframe */
.page {
  padding: 50px 30px 0 30px;
}

.margin {
  margin: 10px 0;
}

.justify-content-end {
  justify-content: flex-end;
}
.button {
  color: #fff;
  cursor: pointer;
  transition: all 0.2s;
  border-radius: 8px
}

.button:hover {
  background-color: #8C2C7E;
}

.button-light {
  /*background: linear-gradient(90deg,#CA357E 0, #8C2C7E 100%); */
  border: 2px solid #8C2C7E;
  background: #FFF;
  color: #8C2C7E;
}
/*
.button-light:after {
  content: attr(data);
  font-size: 16px;
  background: linear-gradient(90deg,#CA357E 0, #8C2C7E 100%);
  -webkit-background-clip: text;
  color: transparent;
  transition: 200ms
}

.button-light::before {
  content: "";
  position: absolute;
  top: 1px;
  right: 1px;
  bottom: 1px;
  left: 1px;
  background-color: white;
  border-radius: 4px;
  z-index: -1;
  transition: 200ms;
}*/

.button-light:hover {
  background: linear-gradient(90deg,#CA357E 0, #8C2C7E 100%);
  color: #FFF;
}

.field {
  margin: 0 0 10px;
}

.field label {
  display: block;
  margin: 0 0 5px;
}

.formError {
  color: red;
  margin: 5px 0 10px;
}

.table {
  border-collapse: collapse;
  width: 100%;
}

.table img {
  vertical-align: middle;
  max-width: 70px;
}

.table th,
.table td {
  border-bottom: 1px solid #ddd;
  padding: 10px 15px;
}
table.table-startups th,
table.table-startups td {
  padding: 5px;
}
.table.masterstartups td {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.input {
  border: 1px solid #dadada;
  padding: 0.3em 0.5em;
}

.select {
  border: 1px solid #dadada;
}

.select select {
  display: block;
  padding: 5px;
  width: 100%;
}

.is-flex {
  display: flex;
}

.gap {
  column-gap: 20px;
  row-gap: 20px;
}

.smallgap {
  column-gap: 10px;
}

.align-items-center {
  align-items: center;
}
.align-items-start {
  align-items: start;
}
.flex-wrap {
  flex-wrap: wrap;
}

.file {
  align-items: stretch;
  display: flex;
  justify-content: flex-start;
  position: relative;
}

.file-label {
  align-items: stretch;
  display: flex;
  cursor: pointer;
  justify-content: flex-start;
  overflow: hidden;
  position: relative;
}

.file-cta {
  border-color: #dbdbdb;
  border-radius: 0.375em;
  font-size: 1em;
  padding-left: 1em;
  padding-right: 1em;
  white-space: nowrap;
}

.file-name,
.file-cta {
  border-color: #dbdbdb;
  border-radius: 0.375em;
  font-size: 1em;
  padding-left: 1em;
  padding-right: 1em;
  white-space: nowrap;
  display: flex;
  align-items: center;
}

.file-name {
  border-color: #dbdbdb;
  border-style: solid;
  border-width: 1px 1px 1px 0;
  max-width: 16em;
  overflow: hidden;
  text-align: inherit;
  text-overflow: ellipsis;
}

.file-cta {
  background-color: #f5f5f5;
  color: #4a4a4a;
  display: flex;
}

.file-icon {
  align-items: center;
  display: flex;
  height: 1em;
  justify-content: center;
  margin-right: 0.5em;
  width: 1em;
}

.file-input {
  height: 100%;
  left: 0;
  opacity: 0;
  outline: 0;
  position: absolute;
  top: 0;
  width: 100%;
}

.file.has-name .file-name {
  border-bottom-left-radius: 0;
  border-top-left-radius: 0;
}

.sessionform .react-datepicker {
  position: absolute;
}

.language-switcher {
  position: absolute;
  font-weight: 500;
  font-style: italic;
  right: 20px;
  top: 20px;
}
i.spaced {
  margin-left: 3px;
  margin-right: 3px;
}
.center {
  text-align: center;
}
.break {
  flex-basis: 100%;
  height: 0;
}

.sessions-day-selector .MuiFormControlLabel-label {
  font-size: 1.2rem;
}
.sessions-day-selector .selected .MuiFormControlLabel-label {
  font-weight: 700;
}

.page .ReactCrop {
  max-width: 500px;
  max-height: 500px;
}

.startups-actions button {
  margin-right: 3px;
}

.image-container {
  width: 500px;
  height: 500px;
  text-align: center;
  display: flex;
  /* flex-direction: row; */
  justify-content: center;
  align-items: center;
}
.speaker-warning, .presence-warning {
  font-size: 12px;
}
.speaker-warning i {
  font-style: italic;
}
.speaker-warning b {
  font-weight: 600;
}
.gold {
  color: #FFD700;
}
.valign-middle {
  vertical-align: middle;
}
.sessions-dates {
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-bottom: 25px;
  gap: 25px;
}

.sessions-date {
  max-width: 100px;
}
.sessions-date button {
  padding: 6px 14px;
  display: block;
  text-align: center;
  text-transform: none;
  font-size: 16px;
  line-height: 22px;
  font-weight: 700;
  background-color: #EFE9F3;
  border-radius: 20px;
  border: 0;
}
.sessions-date button.selected {
  background-color: #652A83;
  color: #FFF;
}
.sessions-date button:hover {
  border: 0;
}
/*
.sessions-date button .month {
display: block;
}
.sessions-date button .day {
  font-weight: 700;
  font-size: 22px;
}*/
.daily-quota {
  margin-top: 20px;
  height: 120px;
  width: 200px;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  border-radius: 8px;
}
.daily-quota h4 {
  font-size: 22px;
  font-weight: 700;
  margin: 0;
}
.daily-quota hr {
  height: 6px;
  width: 50%;
}
.daily-quota .current-quota {
  font-size: 1.5em;
}
.session-actions, .startup-actions {
  text-align: center;
  align-items: center;
  column-gap: 5px;
}
.session-actions button, .session-actions a, .startup-actions button, .startup-actions a {
  color: #D9D9D9;
}
.session-actions button:hover, .session-actions a:hover, .startup-actions button:hover, .startup-actions a:hover {
  color: #580055;
}

#root *.gradient-vvt {
  background-image: linear-gradient(90deg,rgba(251, 217, 1, 1) 0,rgba(240,78,12,1) 41.15%,rgba(253,10,113,1) 100%);
  color: #FFF;
}

#root *.gradient-vvt2 {
  background-image: linear-gradient(90deg,#CA357E 0, #8C2C7E 100%);
  color: #FFF;
}

#root *.shadow-vvt-2 {
  box-shadow: rgb(112, 34, 133) 0px 0px 20px 0px;
  border: 0;
}

#root *.shadow-vvt {
  box-shadow: rgb(202, 202, 202) 0px 0px 8px 0px;
  border: 0;
}

#root .information {
  color: orangered;
}

.office-hours h2 {
  font-size: 2rem;
}

.office-hours .booking-slots {
  display: flex;
  column-gap: 5px;
  justify-items: start;
  justify-content: start;
  overflow-y: hidden;
  overflow-x: scroll;
  padding-top: 10px;
  /* scrollbar-width: thin; */
  border-radius: 4px;

  /* transform: rotateX(180deg); */
}
/*
.page.officehours-investors .booking-slots > * {
  transform: rotateX(180deg);
}*/

.office-hours .room-slots {
  display: flex; 
  flex-direction: column;
  row-gap: 5px;
}
.session-status .status {
  overflow: hidden;
  text-overflow: ellipsis;
  padding: 5px 12px;
  white-space: nowrap;
  border-radius: 12px;
}
.session-status .status.review {
  background-color: #FBF4E7;
  color: #E4A437;
}
.session-status .status.approved {
  background-color: #E1F3E9;
  color: #55A262;
}
.session-status .status.rejected {
  background-color: #F7EDEB;
  color: #C14E39;
}
.session-status .status.drafted {
  padding: 0;
  border-radius: 0;
}
.startup-info {
  display: flex;
  flex-direction: column;
  gap: 20px;
}
.startup-info p {
  color: #000;
}
p b {
  font-weight: 700;
}
.button.maintheme {
  background: -webkit-linear-gradient(90deg, #E5007E, #9D1980);
  border: none;
  color: white;
  min-height: 44px;
  color: white;
  padding: 0 15px;
  transition: all 0.3s ease;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border-radius: 100px;
  min-width: 80px;
  position: relative;
}
.button.maintheme-light:hover {
  transform: scale(1.1);
} 
.button.maintheme-light span {
  position: relative;
  z-index: 2;
  background: -webkit-linear-gradient(90deg, #E5007E, #9D1980);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-weight: bold;
  display: flex;
  align-items: center;
  justify-content: center;
}
.button.maintheme-light::before {
  content: "";
  position: absolute;
  background: -webkit-linear-gradient(90deg, #E5007E, #9D1980);
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
  width: 100%;
  height: 100%;
  border-radius: 5px;
}
.button.maintheme-light::after {
  content: "";
  position: absolute;
  background-color: white;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
  width: calc(100% - 4px);
  height: calc(100% - 4px);
  border-radius: 4px;
}
.button.maintheme-light span.overlay {
  bottom: 0;
  display: block;
  left: 0;
  pointer-events: none;
  position: absolute;
  right: 0;
  top: 0;
}
.button.maintheme2 {
  transition: all;
  border-radius: 80px;
  align-items: center;
  display: inline-flex;
  justify-content: center;
  background-color: #CA085A;
  border: 1px solid #CA085A;
  color: white;
}
.button.maintheme2:hover{
  background-color: #fff;
  color: #CA085A;
}
a[role=button], button, input[type=submit], a.button {
  box-sizing: border-box;
  display: inline-block;
  font-size: 1rem;
  font-weight: 700;
  margin: 0;
  padding: .5em 1em;
  text-decoration: none;
  vertical-align: middle;
}