.button {
    background: rgba(29,111,247, 1);
    color: #FFF;
    padding: 10px 20px;
    display: inline-flex;
    column-gap: 10px;
    transition: all .2s;
    align-items: center;
  
    &:disabled {
        opacity: .5;
        cursor: auto;
    }
  
    &:focus:not(:disabled),
    &:hover:not(:disabled) {
        background-color: rgba(10,80,196, 1)
    }
  
    &-green {
        background: #00d1b2;
  
        &:hover:not(:disabled) {
            background: #00c4a7;
        }
    }
}