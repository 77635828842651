.field {
    margin: 0 0 10px;
    
    label {
        display: block;
        margin: 0 0 5px;
    }
}

.textarea,
.input {
    border: 1px solid #dadada;
    padding: .3em .5em;
    transition: all .2s;

    &:disabled, &.disabled {
        background: hsl(0, 0%, 95%);
    }

    &:focus {
        border-color: #2684FF;
    }
}

.input-long {
    border: 1px solid #dadada;
    padding: .3em .5em;
    transition: all .2s;
    width: 100%;

    &:disabled {
        background: hsl(0, 0%, 95%);
    }

    &:focus {
        border-color: #2684FF;
    }
}

.textarea {
    min-height: 8em;
    width: 100%;
}

.checkbox {
    border: 1px solid #dadada;
    height: 15px;
    width: 15px;
    transition: all .2s;

    &:checked {
        background: #2684FF;
    }

    + label {
        display: inline-block;
        margin: 0 0 0 10px;
    }
}

.formError {
    color: red;
    margin: 5px 0 10px;
}

.legend {
    font-size: 1.5rem;
    font-weight: 500;
    margin: 0 0 10px;
}