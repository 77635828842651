@import "./helpers";
@import "./button";
@import "./typography";
@import "./file";
@import "./form";
@import "./table";

html {
  font-size: 14px;
  font-family: "Poppins", sans-serif;
  color: #333;
  line-height: 1.4;
}

a:not(.button),
button:not(.button) {
  color: inherit;

  i {
    margin: 0 10px 0 0;
  }
}

button {
  cursor: pointer;
}

.table-startups {
  td {
    text-overflow: ellipsis;
    overflow-x: hidden;
  }

  i {
    font-size: 18px;
  }
}
.startupDetails {
  display: block;

  span {
    margin-left: 10px;
  }
}

.detailsItem {
  margin: 10px 0;

  .label {
    font-weight: 700;
    display: block;
  }
}

.notification {
  display: flex;
  align-items: center;

  i {
    margin: 0 10px 0 0;
  }
}

.warning {
  color: #e58624;
}
.information {
  color: #1d6ff7;
}

.modal {
  border: 1px solid #999;
  background: #fff;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 20px;
  min-width: 500px;

  .buttons {
    display: flex;
    margin: 20px 0 0;
    column-gap: 20px;
    justify-content: center;
  }
}

.loader {
  justify-content: center;
}
.loader-left {
  justify-content: start;
}

.profilePicture, .thumbnailPicture {
  border: 1px solid #dadada;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  cursor: pointer;

  img {
    height: 100%;
    width: 100%;
    object-fit: cover;
  }

  i {
    background: #444;
    color: #fff;
    cursor: pointer;
    position: absolute;
    top: 0;
    right: 0;
    padding: 5px;
    pointer-events: none;
  }
}

.profilePicture {
  height: 100px;
  width: 100px;
  
  &.changing-picture {
    i, input, img { 
      display: none;
    }
  }
}

.thumbnailPicture {
  height: 200px;
  width: 200px;
}
table.agorize {
  td {
    line-height: 28px;
    word-wrap: break-word;
  }
}
tr.agorize-selected {
  background-color: #00d1b2;

  i {
    font-size: 24px;
  }
}
