h1, h2, h3, h4, h5, h6 {
    font-weight: 500;
    margin: 0 0 .8em;
}

h1 { font-size: 2.5rem; }
h2 { font-size: 2rem; }
h3 { font-size: 1.5rem; }

.titleImage {
    img {
        max-width: 120px;
        margin: 0 20px 0 0;
        vertical-align: middle;
    }
}