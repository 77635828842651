.table {
    border-collapse: collapse;
    table-layout: fixed;
    width: 100%;

    th, td {
        border-bottom: 1px solid #ddd;
        padding: 10px 15px;
    }

    th {
        > span { font-weight: 700; }
    }

    img {
        vertical-align: middle;
        max-width: 70px;
    }

    .buttons {
        display: flex;
        column-gap: 20px;
        justify-content: flex-end;
    }
}

.pagination {
    display: flex;
    column-gap: 10px;
    align-items: center;
}