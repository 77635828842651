.text-center {
    text-align: center;
}

.text-right {
    text-align: right;
}

.margin {
    margin: 20px 0;
}

.flex-container
{
  display: flex;
  flex-direction: row;
}

.flex-item
{
    flex-shrink: 0;
    margin-right: 20px;
}

.flex-item-center
{
    align-self: center;
}

.padding {
    padding: 20px 0;
}

.is-flex {
    display: flex;
}

.gap {
    column-gap: 20px;
}

.rounded {
    border-radius: 50%;
}

.shadow {
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.15);
}

.w-250 {
    width: 250px;
}

.w-350 {
    width: 350px;
}

.w-720 {
    width: 720px;
}